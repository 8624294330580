
export default {
    name: 'MonthlyNewTab',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        tab: Object,
        middleEmit: Object
    },
    data() {
        return {
            stickyCompo: {
                stickyTag: '.Head-box',
            },
            target: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleTime(obj) {
            let list = obj.list;
            list.forEach((p, i) => {
                p.props = {}
                if(p.value == obj.value) {
                    p.props['title-style'] = `background: ${this.tab.timeBgActive}`
                }
            })
            this.tab.timeList = list
        },
        changeTab() {
            this.middleEmit.fn('changeTab')
        },
    },
}
