
import fnApi from '/utils/common/methods'
import ActivityHead from '../components/ActivityHead'
import ActivityList from '../components/ActivityList'
import FlashSaleTab from './components/FlashSaleTab'
import FlashSaleDesc from './components/FlashSaleDesc'

export default {
    name: 'flashSale',
    layout: 'main-head',
    components: {
        ActivityHead,
        ActivityList,
        FlashSaleTab,
        FlashSaleDesc
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        detail: Object
    },
    data() {
        return {
            fnApi: fnApi,
            middleEmit: {},
            currency: {},
            query: {},
            app: {},
            sticky: {},
            seo: {},
            info: {},        // 活动所有信息
            list: [],        // 活动列表
            ad: {},          // 广告
            shareInfo: {},   // 分享信息
            filter: {},      // 筛选信息
            hasMore: false,
            skeleton: true,
            tab: {                  // 标签栏
                visible: false,
                flashId: '',
                active: 0,
                limit: 0,           // 当前场次限定数量
                hasLimit: 0,        // 是否限购 0 不限 1 限购
                lineWidth: 0,
                list: [],           // 标签数据
            },
            activity: {            // 闪购活动时间
                list: [],
                countDownTime: 0,   // 倒计时时间
                activityStatus: 2,  // 当前场次活动状态 1 未开始 2 已开始
            },
            page: {          // 分页
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 获取活动信息
        getInfoAjax() {
            this.$api.activity.getFlashSaleInfo({}, {
                headers: this.app.headers
            }).then(response => {
                const res = response.result || [];
                this.info = res
                this.setTabData()
                this.setCountTime()
                this.getListAjax()
            })
        },
        setCountTime() {
            const target = this.info.filter(p => {
                return p.flashId == this.tab.flashId
            })[0]
            this.activity.activityStatus = target.activityStatus
            this.activity.countDownTime = this.activity.activityStatus == 1 ? target.startIn : target.endIn
            // 限定商品数量
            this.tab.hasLimit = target.shopLimit
            this.tab.limit = target.limitNumber
        },
        setTabData() {
            this.tab.list = this.info || []
            this.tab.flashId = this.tab.list[0].flashId
            this.tab.list.forEach(p => {
                p.desc = p.activityStatus == 1 ? `${this.$dayjs(p.activityStartDate).format('MMM D HH:mm')}` : this.$translate('Sale Now')
                p.props = {}
                if(p.flashId == this.tab.flashId) {
                    p.props['title-style'] = `background: #a23f3e`
                }
            })
            this.tab.visible = this.tab.list.length > 1
        },
        setShareInfo() {
            let img = this.list[0].pics || {};
            this.shareInfo = {
                image: img.url,
                description: this.info.seoRemarks || 'false sale',
                title: this.info.seoTitle || 'false sale',
                url: window.location.href
            }
        },
        // 获取列表
        getListAjax() {
            this.$api.activity.getFlashSaleList({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
                activityId: this.query.activityId,
                flashId: this.tab.flashId
            }, {
                headers: this.app.headers
            }).then(response => {
                const res = response.result || {};
                this.page.totalPage = res.totalPage
                if(this.hasMore) {
                    this.list = [...this.list, ...res.list]
                } else {
                    this.list = res.list || [];
                }
                this.skeleton = false
                this.setShareInfo()
            })
        },
        // tab
        changeTab() {
            this.hasMore = false
            this.page.pageNum = 1
            this.getListAjax()
            this.setScrollTo()
        },
        setScrollTo() {
            this.$VueScrollTo.scrollTo('.ActivityList', '', {
                offset: -50
            })
        },
        // app
        emitSetBar(obj) {
            this.sticky = obj
        },
        emitGetHeader(obj) {
            this.app = obj.app
            this.currency = obj.currency
            this.query = {
                ...this.detail,
                ...obj.query
            }
            this.getInfoAjax()
        },
        // emit
        emitBottomLoading(e) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.hasMore = true
                this.page.pageNum += 1
                this.getListAjax()
            }
            return hasNext
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
        }
    },
}
