
import fnApi from '/utils/common/methods'
import ActivityHead from '../components/ActivityHead'
import ActivityAd from '../components/ActivityAd'
import ActivityList from '../components/ActivityList'
import ActivitySubmit from '../components/ActivitySubmit'
import FreeGiftFree from './components/FreeGiftFree'

export default {
    name: 'freegift',
    layout: 'main-head',
    components: {
        ActivityHead,
        ActivityAd,
        ActivityList,
        ActivitySubmit,
        FreeGiftFree
    },
    async asyncData({$api, cookies, ...context}) {
        const query = context.query || {};
        const response = await $api.activity.getFreeGiftInfo({
            activityId: query.activityId,
        }, false);
        const res = response.result || {};
        return {
            seo: res
        }
    },
    props: {
        detail: Object
    },
    data() {
        return {
            fnApi: fnApi,
            middleEmit: {},
            currency: {},
            query: {},
            app: {},
            sticky: {},
            seo: {},
            info: {},        // 活动所有信息
            activity: {},    // 活动信息
            allData: {},     // 活动列表所有信息
            list: [],        // 活动列表
            ad: {},          // 广告
            shareInfo: {},   // 分享信息
            filter: {},      // 筛选信息
            hasMore: false,
            skeleton: true,
            page: {          // 分页
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 获取活动信息
        getInfoAjax() {
            this.$api.activity.getFreeGiftInfo({
                activityId: this.query.activityId,
            }, {
                headers: this.app.headers
            }).then(response => {
                const res = response.result || {};
                this.info = res
                this.activity = res.activityAmt || {}
                this.ad = {
                    url: this.info.appCover,
                    videoUrl: this.info.appCoverVideoUrl || ''
                }
                this.setFree()
                this.setShareInfo()
            })
        },
        setFree() {
            this.info.canSelectFreeGift.forEach(item => {
                item.title = item.productName
                item.salePrice = 0
                item.orgPrice = item.price / 100
                item.displaySalePrice = '0.00'
                item.displayOrgPrice = item.displayPrice
                item.activityLabel = [{
                    status: true,
                    tagText: this.$translate('FREE GIFT')
                }]
            })
        },
        setShareInfo() {
            this.shareInfo = {
                image: this.info.appCover,
                description: this.info.seoRemarks || 'freegift',
                title: this.info.seoTitle || 'freegift',
                url: window.location.href
            }
        },
        // 获取列表
        getListAjax() {
            this.$api.activity.getFreeGiftList({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
                activityId: this.query.activityId,
                needFilter: true,
                ...this.filter.params
            }, {
                headers: this.app.headers
            }).then(response => {
                const res = response.result || {};
                this.allData = res
                this.page.totalPage = res.totalPage
                if(this.hasMore) {
                    this.list = [...this.list, ...res.list]
                } else {
                    this.list = res.list;
                }
                this.skeleton = false
            })
        },
        // app
        emitSetBar(obj) {
            this.sticky = obj
        },
        emitGetHeader(obj) {
            this.app = obj.app
            this.currency = obj.currency
            this.query = {
                ...this.detail,
                ...obj.query
            }
            this.getInfoAjax()
            this.getListAjax()
        },
        // emit
        emitChangeFilter(e) {
            this.hasMore = false
            this.page.pageNum = 1
            this.filter.sortColumn = e.options.mergeSort
            this.filter.params = e.filterResult.params
            this.getListAjax()
            this.setScroll()
        },
        emitBottomLoading(e) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.hasMore = true
                this.page.pageNum += 1
                this.getListAjax()
            }
            return hasNext
        },
        // scroll
        setScroll() {
            this.$VueScrollTo.scrollTo('.ActivityList', '', {
                offset: -50
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.seo.activityName,
                keywords: this.seo.activityName,
                description: this.seo.activityName,
                image: this.seo.appCover
            });
            return {
                ...(this.detail?.activityId ? {} : head)
            }
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
        }
    },
}
