import { render, staticRenderFns } from "./ActivityList.vue?vue&type=template&id=5953ee9a&scoped=true&"
import script from "./ActivityList.vue?vue&type=script&lang=js&"
export * from "./ActivityList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5953ee9a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnSticky: require('/home/php1/m-nuxt/components/YfnSticky.vue').default,YfnList: require('/home/php1/m-nuxt/components/YfnList.vue').default})
