import { render, staticRenderFns } from "./ActivityAd.vue?vue&type=template&id=55d2a933&scoped=true&"
import script from "./ActivityAd.vue?vue&type=script&lang=js&"
export * from "./ActivityAd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55d2a933",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnVideo: require('/home/php1/m-nuxt/components/YfnVideo.vue').default})
