
import ActivityFlashSale from '../flashSale/index'
import ActivityFreeGift from '../freegift/index'
import ActivityFullFold from '../fullFold/index'
import ActivityFullReduce from '../fullReduce/index'
import ActivityList from './components/ActivityList'
import ActivityStatus from './components/ActivityStatus'

export default {
    name: 'Activity-Tmpl',
    layout: 'main-footer',
    components: {
        ActivityFlashSale,
        ActivityFreeGift,
        ActivityFullFold,
        ActivityFullReduce,
        ActivityList,
        ActivityStatus
    },
    async asyncData({$api, cookies, ...context}) {
        const query = context.query || {};
        const params = context.route.params;
        const response = await $api.activity.getActivityInfo({
            activityId: query.activityId,
            seoUrl: params.seoUrl
        }, false);
        const res = response.result || {};
        return {
            seo: res
        }
    },
    props: {},
    data() {
        return {
            middleEmit: {},
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.seo.seoTitle,
                keywords: this.seo.seoRemarks,
                description: this.seo.seoRemarks,
                image: this.seo.appCover || ''
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
        }
    },
}
