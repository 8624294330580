
export default {
    name: 'ActivityHead',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        title: [Number, String],
        fnApi: Object,
        middleEmit: Object,
        shareInfo: Object,
        options: Object
    },
    data() {
        return {
            app: { // app
                hasApp: false,
                style: {},
                headers: {}
            },
            sticky: { // 粘性布局
                top: '50px'
            },
            currency: {},
            query: {},
            bag: {
                quantity: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // app
        handleNav(val) {
            // val -> 1 返回 2 分享 3 购物车
            const bridgeApp = this.fnApi.bridgeApp;
            let name = '';
            let params = {};

            switch(val) {
                case 1:
                    name = 'setBack'
                break;
                case 2:
                    name = 'setShare'
                    params = this.shareInfo
                break;
                case 3:
                    name = 'setBag'
                break;
                default:
            }
            if(val == 2) {
                this.$fnApi.bridgeApp.bridge({
                    name: name,
                    params
                })
            } else {
                this.$fnApi.bridgeApp.bridge({
                    name: name
                })
            }
        },
        setBarHeight() { // app-设置手机信号栏
            if(this.app.hasApp) {
                const bridgeApp = this.fnApi.bridgeApp;
                this.$fnApi.bridgeApp.bridge({
                    name: 'getConfig',
                    hasGetData: true
                }).then(e => {
                    let barHeight = 0;
                    const diff = bridgeApp.getApp().isiOS ? 0 : 13;
                    e && (barHeight = e.barHeight + diff)
                    this.app.style = {
                        height: `${barHeight}px`
                    }
                    // this.sticky.top = `${50} + ${barHeight}px`
                    this.sticky.top = `${0} + ${barHeight}px`
                    this.middleEmit.fn('emitSetBar', this.sticky)
                })
            } else {
                this.middleEmit.fn('emitSetBar', this.sticky)
            }
        },
        getHeaders() { // app-获取Headers
            const bridgeApp = this.fnApi.bridgeApp;
            if(this.app.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'getHeaders',
                    hasGetData: true
                }).then(e => {
                    if(e) {
                        this.app.headers = e
                        this.currency = {
                            symbol: e.currency
                        }
                    }
                    delete this.app.headers.loginTerminal
                    delete this.app.headers.currency
                    this.middleEmit.fn('emitGetHeader', {
                        app: this.app,
                        currency: this.currency,
                        query: this.query
                    })
                    this.$storage.set('language', {
                        identify: e.language
                    }, true)
                })
            } else {
                this.middleEmit.fn('emitGetHeader', {
                    app: this.app,
                    currency: this.currency,
                    query: this.query
                })
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            if(process.client) {
                this.app.hasApp = this.$fnApi.bridgeApp.getApp().isApp
                this.currency = this.$storage.get('currency') || {}
                this.setBarHeight()
                this.getHeaders()
            }
        }
    },
}
