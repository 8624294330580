
export default {
    name: 'ActivityList',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            active: {
                visible: false,
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getActivityListAjax()
    },
    methods: {
        getActivityListAjax() {
            this.$api.activity.getActivityList().then(response => {
                const res = response.result || {};
                const list = Object.keys(res);
                let arr = [];
                list.forEach(key => {
                    const pathName = {
                        activities: 'Activity-FreeGifts',
                        bmOffers: 'Activity-FullFold',
                        reduced: 'Activity-FullReduce',
                        flash: 'Activity-FlashSale',
                    }
                    arr.push({
                            list: res[key],
                            pathName: pathName[key],
                        })
                })
                this.active.list = arr
                this.active.visible = !!this.active.list.length
            })
        },
        handleImg(obj) {
            this.$router.push({
                name: 'Activity-Tmpl',
                params: {
                    seoUrl: obj.seoUrl
                },
                query: {
                    activityId: obj.activityId
                }
            })
        }
    },
}
