
export default {
    name: 'ActivityList',
    components: {},
    asyncData({ api, cookies, ...context }) { },
    props: {
        allData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        list: Array,
        middleEmit: Object,
        hasSticky: {
            type: Boolean,
            default: () => {
                return true
            }
        }
    },
    data() {
        return {
            listCompo: {
                options: {
                    hasMore: true
                }
            },
            stickyCompo: {
                hasNav: true,
                hasSort: true,
                hasFilter: true,
                hasCloseSticky: true,
                stickyTag: '.Head-box',
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() { },
    methods: {
        emitChangeFilter(e) {
            this.middleEmit.fn('emitChangeFilter', e)
        },
        emitBottomLoading(e, callback) {
            const bool = this.middleEmit.fn('emitBottomLoading', e);
            callback(bool)
        },
        emitAdd() {
            this.middleEmit.fn('getInfoAjax')
        },
        // 初始化
        initialSet() {
            const hasApp = this.$fnApi.bridgeApp.getApp().isApp;
            if (hasApp) {
                this.stickyCompo.stickyTag = ''
                this.stickyCompo.stickyTop = 50
            }
        }
    },
}
