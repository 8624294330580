import { render, staticRenderFns } from "./FreeGiftFree.vue?vue&type=template&id=0131a690&scoped=true&"
import script from "./FreeGiftFree.vue?vue&type=script&lang=js&"
export * from "./FreeGiftFree.vue?vue&type=script&lang=js&"
import style0 from "./FreeGiftFree.vue?vue&type=style&index=0&id=0131a690&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0131a690",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnProduct: require('/home/php1/m-nuxt/components/YfnProduct.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default})
