
export default {
    name: 'BestSellerTab',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        tab: Object,
        middleEmit: Object
    },
    data() {
        return {
            stickyCompo: {
                stickyTag: '.Head-box',
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        changeTab() {
            this.middleEmit.fn('changeTab')
        }
    },
}
