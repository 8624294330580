
import fnApi from '/utils/common/methods'
import ActivityHead from '../components/ActivityHead'
import ActivityAd from '../components/ActivityAd'
import ActivityList from '../components/ActivityList'
import BestSellerTab from './components/BestSellerTab'

export default {
    name: 'bestSeller',
    layout: 'main-head',
    components: {
        ActivityHead,
        ActivityAd,
        ActivityList,
        BestSellerTab
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            fnApi: fnApi,
            middleEmit: {},
            currency: {},
            query: {},
            app: {},
            sticky: {},
            info: {},        // 活动所有信息
            activity: {},    // 活动信息
            list: [],        // 活动列表
            ad: {},          // 广告
            shareInfo: {},   // 分享信息
            filter: {},      // 筛选信息
            hasMore: false,
            skeleton: true,
            tab: {
                cateId: '',
                visible: false,
                list: []
            },
            page: {          // 分页
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 获取活动信息
        getInfoAjax() {
            this.$api.activity.getBestSellerInfo({
                chartsId: this.query.chartsId,
            }, {
                headers: this.app.headers
            }).then(response => {
                const res = response.result || {};
                this.info = res
                this.activity = this.info || {}
                this.ad = {
                    url: this.info.appCover
                }
                this.setTabData()
                this.setShareInfo()
                this.getListAjax()
            })
        },
        setTabData() {
            this.tab.list = this.info.mallSalesCateDOList || []
            this.tab.cateId = this.tab.list[0].id
            this.tab.visible = this.tab.list.length > 1
        },
        setShareInfo() {
            this.shareInfo = {
                image: this.info.appCover,
                description: this.info.seoRemarks || 'best seller',
                title: this.info.seoTitle || 'best seller',
                url: window.location.href
            }
        },
        // 获取列表
        getListAjax() {
            this.$api.activity.getBestSellerList({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
                cateId: this.tab.cateId,
            }, {
                headers: this.app.headers
            }).then(response => {
                const res = response.result || [];
                this.page.totalPage = res.totalPage || 1
                if(this.hasMore) {
                    this.list = [...this.list, ...res]
                } else {
                    this.list = res;
                }
                this.skeleton = false
            })
        },
        // tab
        changeTab() {
            this.hasMore = false
            this.page.pageNum = 1
            this.getListAjax()
            this.setScrollTo()
        },
        setScrollTo() {
            this.$VueScrollTo.scrollTo('body')
        },
        // app
        emitSetBar(obj) {
            this.sticky = obj
        },
        emitGetHeader(obj) {
            this.app = obj.app
            this.currency = obj.currency
            this.query = obj.query
            this.getInfoAjax()
        },
        // emit
        emitChangeFilter(e) {
            this.hasMore = false
            this.page.pageNum = 1
            this.filter.sortColumn = e.options.mergeSort
            this.getListAjax()
            this.setScroll()
        },
        emitBottomLoading(e) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.hasMore = true
                this.page.pageNum += 1
                this.getListAjax()
            }
            return hasNext
        },
        // scroll
        setScroll() {
            this.$VueScrollTo.scrollTo('.ActivityList', '', {
                offset: -50
            })
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
        }
    },
}
