
export default {
    name: 'FlashSaleDesc',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        tab: Object,
        activity: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        changeCountDown() {
            this.$router.go(0)
        }
    },
}
