
export default {
    name: 'ActivityStatus',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleBtn() {
            if(this.info == 1) {
                this.$router.back()
            } else {
                this.$router.push({
                    name: 'Home'
                })
            }
        }
    },
}
