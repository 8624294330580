
export default {
    name: 'FreeGiftFree',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        list: Array
    },
    data() {
        return {
            swiperCompo: {
                options: {
                    slidesPerView: 'auto',
                }
            },
            productCompo: {
                options: {
                    hasColumn: true,
                    hasQuantity: false,
                    img: {
                        width: '140px',
                        height: '140px'
                    }
                }
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
